<template>
	<div class="tabbar--container">
		<span
			v-for="(route, index) in routes"
			:key="index"
			class="tabbar--item--container"
			@click="triggerVVVV(route.identifier)"
		>
			<router-link
				:to="`/${route.parentId}/${route.id}`"
				event="click"
				exact
				class="tabbar--item"
				active-class="tabbar--item-active"
			>
				<p>{{ route.label }}</p>
				<div class="tabbar--item--divider"></div>
			</router-link>
		</span>
	</div>
</template>

<script>
export default {
	name: "TabBar",
	props: {
		routes: {
			type: Object,
			default () {
				return {}
			}
		}
	},
	methods: {
		triggerVVVV: function(identifier) {
			this.$store.commit("triggerVVVV", identifier)
		}
	}
}
</script>

<style scoped>
	.tabbar--container {
		display: flex;
		justify-content: flex-end;
		flex-shrink: 0;
		height: 10.625em;
	}

	.tabbar--item--container {
		flex-basis: 45em;
	}

	.tabbar--item--container:last-child a .tabbar--item--divider {
		opacity: 0;
	}

	.tabbar--item {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		font-size: 2.8rem;
		color: rgba(255, 255, 255, 0.4);
		background-color: rgba(209, 246, 243, 0.05);
		text-decoration: none;
		text-align: center;
		user-select: none;
	}

	.tabbar--item-active {
		color: rgba(15, 22, 33, 0.8);
		background-color: rgb(45, 213, 201);
	}

	.tabbar--item--divider {
		height: 4.375rem;
		width: 0.0625rem;
		position: absolute;
		right: 0;
		background-color: rgba(255, 255, 255, 0.4);
	}
</style>
