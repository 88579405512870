<template>
	<div class="app--container">
		<div class="app--pane--left">
			<div
				v-show="showFrame"
				class="app--pane--left--content"
			>
				<div
					class="app--pane--left--half"
				>
					<div
						v-for="item in getCurrentContent(this.$route.path)"
						:key="item.id"
						:class="{'video--container': item.type === 'video'}"
					>
						<video
							v-if="item.type === 'video'"
							:class="{'display': getVideoLoop, 'display-none': !getVideoLoop }"
							autoplay
							loop
							muted
							:src="getImagePath(item.value.loop)"
						></video>
						<video
							v-if="item.type === 'video'"
							:class="{'fade-away': getVideoLoop}"
							@ended="replaceIntroWithLoop"
							autoplay
							muted
							:src="getImagePath(item.value.intro)"
						></video>
					</div>
				</div>
				<div class="app--pane--right--half">
					<div
						v-for="item in getCurrentContent(this.$route.path)"
						:key="item.id"
						:class="{ 'wrapper-image': item.type === 'image'}"
					>
						<img
							v-if="item.type === 'image'"
							:src="getImagePath(item.value)"
						>
						<h1 v-if="item.type === 'headline'">
							{{ item.value }}
						</h1>
						<p v-if="item.type === 'text'">
							{{ item.value }}
						</p>	
					</div>
				</div>
			</div>
		</transition>
			<TabBar
				:routes="getSubRoutes(currentMainRoutePath)"
			></TabBar>
		</div>
		<div class="app--pane--right">
			<NavigationMenu
				:routes="getMainRoutes"
			></NavigationMenu>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex"

import TabBar from "./components/TabBar"
import NavigationMenu from "./components/NavigationMenu"

export default {
	name: "App",
	components: {
		TabBar,
		NavigationMenu
	},
	data() {
		return {
			loop: false,
			valueKey: "intro"
		}
	},
	computed: {
		showFrame: function () {
			const list = this.getCurrentContent(this.$route.path)
			if (list === undefined || list.length < 1) {
				return false
			} else {
				return true
			}
		},
		currentMainRoutePath: function () {
			const route = this.$route.path.split("/")[1]

			return route
		},
		...mapGetters([
			"getVideoLoop",
			"getMainRoutes",
			"getSubRoutes",
			"getCurrentContent",
			"getLastIdentifier",
			"getSteps",
			"getContentRouteList",
			"getImageMap"
		])
	},
	mounted: function () {
		const store = this.$store
		const router = this.$router
		const that = this

		window.AppService = {
			loadUseCase: function (content, imageMap) {
				const json = JSON.stringify(content).replace("\n", "\\n")
				store.commit("setImageMap", imageMap)
				store.dispatch("parseAndFormat", JSON.parse(json))

				const contentList = that.getContentRouteList

				router.push(contentList[0].path)
				store.commit("triggerVVVV", contentList[0].identifier)
				that.registerPresentationNavigation()
			}
		}

		window.AppService.loadUseCase(CONTENT, IMAGEMAP)
	},
	methods: {
		replaceIntroWithLoop: function () {
			if (this.getVideoValueKey === "loop") {
				this.$store.commit("setVideoLoop", false)
			} else {
				this.$store.commit("setVideoLoop", true)
			}
		},
		getImagePath: function (imageKey) {
			const imageMap = this.getImageMap
			return imageMap[imageKey]
		},
		registerPresentationNavigation: function () {
			const timeline = this.getContentRouteList

			window.prevSlide = () => {
				for (const index in timeline) {
					if (timeline[index].path === this.$route.path) {
						const prevIndex = parseInt(index) - 1

						if (prevIndex >= 0) {
							this.$router.push(timeline[prevIndex].path)
							this.$store.commit("triggerVVVV", timeline[prevIndex].identifier)
						} else {
							console.log("[INFO] Currently at first step.") //eslint-disable-line
						}

						break
					}
				}
			}

			window.nextSlide = () => {
				for (const index in timeline) {
					if (timeline[index].path === this.$route.path) {
						const nextIndex = parseInt(index) + 1

						if (nextIndex < timeline.length) {
							this.$router.push(timeline[nextIndex].path)
							this.$store.commit("triggerVVVV", timeline[nextIndex].identifier)
						} else {
							console.log("[INFO] Currently at last step.") //eslint-disable-line
						}

						break
					}
				}
			}
		}
	}
}
</script>

<style>
	@media screen and (max-width: 3839px) {
		html {
			font-size: 0.5em;
		}
	}
	@media screen and (min-width: 3840px) {
		html {
			font-size: 1em;
		}
	}

	@font-face {
		font-family: "FiraGo Regular";
		src: url("../statics/fonts/FiraGO-Regular.woff2") format("woff2")
	}
	
	@font-face {
		font-family: "FiraGo Medium";
		src: url("../statics/fonts/FiraGO-Medium.woff2") format("woff2")
	}

	@font-face {
		font-family: "FiraGo Book";
		src: url("../statics/fonts/FiraGO-Book.woff2") format("woff2")
	}

	
	@font-face {
		font-family: "FiraGo Bold";
		src: url("../statics/fonts/FiraGO-Bold.woff2") format("woff2")
	}

	body {
		margin: 0;
		height: 100vh;
		width: 100vw;
		font-family: "FiraGO Regular", sans-serif;
	}

	p {
		white-space: pre-line;
	}

	video {
		height: 100rem;
		width: 100rem;
	}

	.app--container {
		height: calc(100% - 4.625rem - 15.4376rem);
		width: calc(100% - 4.625rem * 2);
		/*padding: 15.4376rem 4.625rem 4.625rem 17.8125rem;*/
		padding: 15.4376rem 4.625rem 4.625rem 4.625rem;
		position: relative;
		display: flex;
	}

	.app--pane--left {
		flex-basis: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	.app--pane--left--content {
		height: 100%;
		margin-bottom: 10.625rem;
		background: rgba(255, 255, 255, 0);
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		font-family: "FiraGO Book", sans-serif;
		font-size: 4.2rem;
		color: rgba(255, 255, 255, 0.8);
		overflow: hidden;
	}

	.app--pane--left--content h1,
	.app--pane--left--content p {
		font-size: 4rem;
		margin: 0 0 8rem 0;
		line-height: 1.45;
	}

	.app--pane--left--content h1 {
		color: rgb(45, 213, 201);
	}

	.app--pane--left--content .app--pane--left--half {
		display: flex;
		margin: 0 4rem;
		width: 60%;
		height: 100%;
	}

	.app--pane--left--content div img,
	.app--pane--left--content-image {
		height: 34rem;
		margin-bottom: 4rem;
	}

	.app--pane--left--half {
		display: flex;
		flex-direction: column-reverse;
	}

	.wrapper-image {
		display: flex;
		height: 30%;
	}

	.app--pane--right--half {
		display: flex;
		flex-direction: column-reverse;
		width: 40%;
		padding-left: 8rem;
	}

	.app--pane--right {
		flex-basis: 24vw;
		display: flex;
		align-items: center;
		justfiy-content: center;
	}

	.display {
		display: block;
	}

	.display-none {
		display: none;
	}

	.fade-away {
		opacity: 1;
		animation: fadeout 0.3s forwards;
	}

	.video--container {
		position: relative;
		height: 100%;
		width: 85rem;
	}

	.video--container video {
		position: absolute;
	}

	@keyframes fadeout {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
	
	@keyframes fadeinfromabove {
		0% {
			margin-top: -10rem;
			opacity: 0;
		}
		100% {
			margin-top: 0rem;
			opacity: 1;
		}
	}
	
	@keyframes fadein {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
</style>
