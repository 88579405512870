<template>
	<div class="navigationmenu--container">
		<div class="navigationmenu--dottedline"></div>
		<span
			v-for="(route, index) of routes"
			:key="index"
			class="navigationmenu--item--container"
			@click="triggerVVVV(route)"
		>
			<router-link
				:to="'/' + route.id"
				event="click"
				class="navigationmenu--item"
				active-class="navigationmenu--item-active"
			>
				{{ route.label }}
				<svg class="navigationmenu--item--icon">
					<rect></rect>
				</svg>
			</router-link>
		</span>
	</div>
</template>

<script>
export default {
	name: "NavigationMenu",
	props: {
		routes: {
			type: Object,
			default () {
				return {}
			}
		}
	},
	methods: {
		triggerVVVV: function(identifier) {
			if (Object.keys(identifier.children).length > 0) {
				const firstChild = Object.keys(identifier.children)[0]
				const firstChildIdentifier = identifier.children[firstChild].identifier
				this.$store.commit("triggerVVVV", firstChildIdentifier)
				this.$router.push(this.getFirstChildRoute(identifier))
			}
		},
		getFirstChildRoute: function (route) {
			const parentPath = route.id
			const firstChild = Object.keys(route.children)[0]
			const childPath = (firstChild === undefined) ? "" : firstChild

			return `/${parentPath}/${childPath}`
		}
	}
}
</script>

<style scoped>
	.navigationmenu--container {
		width: 100%;
		display: flex;
		align-items: flex-end;
		flex-direction: column;
		position: relative;
	}

	.navigationmenu--dottedline {
		width: 0.0625rem;
		height: calc(100% - 4rem);
		position: absolute;
		right: 1.25rem;
		top: 2rem;
		border-right: 0.25rem dotted rgba(255, 255, 255, 1);
	}

	.navigationmenu--item--container {
		padding: 2.5rem 0;
	}

	.navigationmenu--item--container:last-child {
		margin-bottom: 0;
	}

	.navigationmenu--item {
		font-size: 3em;
		color: rgba(255, 255, 255, 0.3);
		text-decoration: none;
		user-select: none;
	}

	.navigationmenu--item-active {
		font-family: "FiraGo Book", sans-serif;
		color: rgba(255, 255, 255, 1);
	}

	.navigationmenu--item--icon {
		height: 1.5625rem;
		width: 1.5625rem;
		transform: rotate(45deg) scale(0.8);
		border: 0.50rem solid rgba(250, 184, 0, 0.0);
	}

	.navigationmenu--item--icon rect {
		height: 100%;
		width: 100%;
		fill: rgb(255, 255, 255);
	}

	.navigationmenu--item-active .navigationmenu--item--icon {
		border: 0.50rem solid rgba(250, 184, 0, 0.2);
		transform: rotate(45deg) scale(1);
	}
	.navigationmenu--item-active .navigationmenu--item--icon rect {
		fill: rgb(250, 184, 0);
	}
</style>
