import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		content: {},
		steps: [],
		contentRouteList: [],
		lastIdentifier: "",
		imageMap: [],
		video: {
			loop: false,
			valueKey: "intro"
		}
	},
	getters: {
		getVideoLoop: state => {
			return state.video.loop
		},
		getVideoValueKey: state => {
			return state.video.valueKey
		},
		getImageMap: state => {
			return state.imageMap
		},
		getContentRouteList: state => {
			return state.contentRouteList
		},
		getSteps: state => {
			return state.steps
		},
		getMainRoutes: state => {
			return state.content
		},
		getSubRoutes: state => id => {
			const parent = state.content[id]
			const visibleSubRoutes = {}

			if (parent === undefined) return {}

			for (const child_id in parent.children) {
				const child = parent.children[child_id]
				const content = child.content_elements
				const label = child.label

				if (content !== undefined && label !== undefined && content.length > 0) {
					visibleSubRoutes[child.id] = child
				}
			}

			return visibleSubRoutes
		},
		getCurrentContent: state => path => {
			const list = state.contentRouteList

			for (const item of list) {
				if (item.path === path) {
					return item.content_elements
				}
			}
		},
		getLastIdentifier: state => {
			return state.lastIdentifier
		}
	},
	mutations: {
		setVideoLoop (state, value) {
			state.video.loop = value
		},
		setVideoValueKey (state, value) {
			state.video.valueKey = value
		},
		setImageMap (state, arrayImageMap) {
			state.imageMap = arrayImageMap
		},
		parseJsonContent (state, jsonContent) {
			let content = {}

			for (const item of jsonContent.structure) { // eslint-disable-line
				let children = {}
				// const id = item.id.toLowerCase().replace(/ /g, "_")
				const id = item.id

				for (const child of item.children) {
					children[child.id] = child
					children[child.id].parentId = id
				}

				content[id] = {
					id: item.id,
					label: item.label,
					content_elements: item.content_elements,
					children: children
				}
			}

			state.content = content
			state.steps = jsonContent.steps
		},
		createContentRouteList (state, jsonContent) {
			const list = []

			const toPath = (string) => {
				if (string !== undefined) {
					// return `/${string.toLowerCase().replace(/ /g, "_")}`
					return `/${string}`
				}
			}

			for (const item of jsonContent.structure) { // eslint-disable-line
				const itemPath = toPath(item.id)

				// list.push({
				// 	id: item.id,
				// 	label: item.label,
				// 	identifier: item.identifier,
				// 	content_elements: item.content_elements,
				// 	path: itemPath
				// })

				if (item.children.length > 0) {
					let lastContent = undefined
					for (const childItem of item.children) {
						let contentElements = childItem.content_elements

						if (contentElements === undefined) {
							contentElements = lastContent
						} else {
							lastContent = contentElements
						}

						list.push({
							id: childItem.id,
							label: childItem.label,
							identifier: childItem.identifier,
							content_elements: contentElements,
							path: itemPath + toPath(childItem.id)
						})
					}
				}
			}

			state.contentRouteList = list
		},
		triggerVVVV (state, identifier) {
			// resetting video store
			state.video.loop = false
			state.video.valueKey = "intro"

			state.lastIdentifier = identifier
			vvvv.activeId(identifier) // eslint-disable-line
		},
		setLastIdentifier (state, value) {
			state.lastIdentifier = value
		}
	},
	actions: {
		parseAndFormat ({ commit }, json) {
			commit("parseJsonContent", json)
			commit("createContentRouteList", json)
		}
	}
})

export default store
